(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('WorkspaceInfoSettingController', WorkspaceInfoSettingController);

    /* @ngInject */
    function WorkspaceInfoSettingController($scope, $state, $q, Setting, $filter, ToastService, $window) {
        var vm = this;

        vm.loadingData = false;
        vm.pageTitleKey = $state.$current.data.pageTitle;
        vm.timezones = [];
        vm.languages = [];
        vm.companySetting = [];
        vm.pageSizes = [{
            "size": "10"
        }, {
            "size": "20"
        }, {
            "size": "40"
        }, {
            "size": "60"
        }, {
            "size": "80"
        }, {
            "size": "100"
        }];
        vm.toastPositions = [{
            "label": "Top Right",
            "value": "top-right"
        }, {
            "label": "Bottom Right",
            "value": "bottom-right"
        }, {
            "label": "Top Left",
            "value": "top-left"
        }, {
            "label": "Bottom Left",
            "value": "Bottom-left"
        }];
        vm.toastPosition = [20, 40, 60, 80, 100];
        vm.boardRefreshTimechoices = [
            {
                "label": "Never refresh",
                "value": "0"
            },
            {
                "label": "Every 15 min",
                "value": "15"
            },
            {
                "label": "Every 30 min",
                "value": "30"
            },
            {
                "label": "Every 45 min",
                "value": "45"
            },
            {
                "label": "Every 60 min",
                "value": "60"
            }
         ];
        vm.companyModel = {
            name: null,
            website_url: null,
            phone_number: null,
            owner_name: null,
            owner_email: null,
            atheer_url: null,
            timezone_id: null,
            language_id: null,
            default_language: false,
            default_page_size: 20,
            default_toast_position: 'top-right',
            terms_url: null,
            privacy_policy_url: null,
            board_refresh_rate: 0
        };

        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getSystemTimezones({
                        size: 100
                    }).$promise,
                    Setting.getSystemLanguages({
                        localisation_support: true
                    }).$promise,
                    Setting.getCompanySettings().$promise
                ])
                .then(function(results) {
                    vm.timezones = results[0];
                    vm.languages = results[1];
                    setCompanyModel(results[2]);
                    vm.loadingData = false;
                });
        };

        function setCompanyModel(settings) {
            angular.forEach(settings, function(setting) {
                if (setting.category == 'company_info') {
                    vm.companySetting = setting;

                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'name') {
                            vm.companyModel.name = settingField.value;
                        }
                        if (settingField.name == 'website_url') {
                            vm.companyModel.website_url = settingField.value;
                        }
                        if (settingField.name == 'phone_number') {
                            vm.companyModel.phone_number = settingField.value;
                        }
                        if (settingField.name == 'owner_name') {
                            vm.companyModel.owner_name = settingField.value;
                        }
                        if (settingField.name == 'owner_email') {
                            vm.companyModel.owner_email = settingField.value;
                        }
                        if (settingField.name == 'timezone_id') {
                            vm.companyModel.timezone_id = settingField.value;
                        }
                        if (settingField.name == 'language_id') {
                            vm.companyModel.language_id = settingField.value;
                        }
                        if (settingField.name == 'atheer_url') {
                            vm.companyModel.atheer_url = settingField.value;
                        }
                        if (settingField.name == 'default_language') {
                            vm.companyModel.default_language = settingField.value == "true" ? true : false;
                        }
                        if (settingField.name == 'default_page_size') {
                            vm.companyModel.default_page_size = settingField.value;
                        }
                        if (settingField.name == 'default_toast_position') {
                            vm.companyModel.default_toast_position = settingField.value;
                        }
                        if (settingField.name == 'terms_url') {
                            vm.companyModel.terms_url = settingField.value;
                        }
                        if (settingField.name == 'privacy_policy_url') {
                            vm.companyModel.privacy_policy_url = settingField.value;
                        }
                        if (settingField.name == 'board_refresh_rate') {
                            vm.companyModel.board_refresh_rate = settingField.value;
                        }
                    });
                }
            });
        }

        function setCompanySettings() {
            setCompanySettingField("name", vm.companyModel.name);
            setCompanySettingField("website_url", vm.companyModel.website_url);
            setCompanySettingField("phone_number", vm.companyModel.phone_number);
            setCompanySettingField("owner_name", vm.companyModel.owner_name);
            setCompanySettingField("owner_email", vm.companyModel.owner_email);
            setCompanySettingField("timezone_id", vm.companyModel.timezone_id);
            setCompanySettingField("language_id", vm.companyModel.language_id);
            setCompanySettingField("default_language", vm.companyModel.default_language);
            setCompanySettingField("default_page_size", vm.companyModel.default_page_size);
            setCompanySettingField("terms_url", vm.companyModel.terms_url);
            setCompanySettingField("privacy_policy_url", vm.companyModel.privacy_policy_url);
            setCompanySettingField("default_toast_position", vm.companyModel.default_toast_position);
            setCompanySettingField("board_refresh_rate", vm.companyModel.board_refresh_rate);
        }

        function setCompanySettingField(fieldName, fieldValue) {
            var foundField = false;
            angular.forEach(vm.companySetting.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.companySetting.fields.push(settingField);
            }
        }

        vm.isURLInvalid = function(url) {
            var URL_REGEX = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?([a-z0-9\-&]*\.)+([a-z]{2,}).*$/i;
            return !URL_REGEX.test(url);
        };

        vm.isAnyURLInValid = function () {
            return (
                vm.isURLInvalid(vm.companyModel.website_url) ||
                vm.isURLInvalid(vm.companyModel.terms_url) ||
                vm.isURLInvalid(vm.companyModel.privacy_policy_url)
            );
        };

        vm.save = function() {
            setCompanySettings();
            Setting.update(vm.companySetting, function() {
                ToastService.displayToastWithAction($filter('triTranslate')("atheer.setting.success"),
                    $filter('translate')('entity.action.refresh'), 'md-warn', 3000, function(res) {
                    if(res == 'ok') {
                        $window.location.reload();
                    }
                });
            });
        }
        vm.loadData();
    }
})();
