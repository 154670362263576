(function () {
    'use strict';

    angular
        .module('atheer.content')
        .config(stateConfig);

    /* @ngInject */
    function stateConfig($stateProvider, CONTENT_INSIGHT_META, CONTENT_RECOGNITION_META) {
        $stateProvider
            .state('storm.content-view', {
                url: '/content/view/:id',
                views: {
                    '@storm': {
                        templateUrl: 'modules/ngx-frame.html',
                        controller: 'ContentViewController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.content.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 20
                    }
                }
            })
            .state('storm.admin-contents', {
                url: '/content?page&sort&search',
                views: {
                    '@storm': {
                        templateUrl: 'modules/content/content.html',
                        controller: 'ContentController',
                        controllerAs: 'vm',
                    },
                    'toolbar@storm': {
                        templateUrl: 'modules/content/content-toolbar.tmpl.html',
                        controller: 'ContentToolbarController',
                        controllerAs: 'vm'
                    },
                    'sideMenu@storm': {
                        templateUrl: 'modules/content/content-menu.tmpl.html',
                        controller: 'ContentMenuController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.content.home.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: true,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 20
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'created_on,desc',
                        squash: true
                    },
                    search: null,
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.content-documents', {
                url: '/content-document?page&sort&search',
                views: {
                    '@storm': {
                        templateUrl: 'modules/ngx-frame.html',
                        controller: 'ContentViewController',
                        controllerAs: 'vm',
                    }
                },
                data: {
                    pageTitle: 'atheer.content.documents.title',
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showSideNavMenu: false,
                        sideNavMenuPosition: 'left',
                        showToolbar: false,
                        sideNavMenuSize: 20
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null,
                    
                },
                resolve: {
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-content-detail', {
                url: '/content/:contentType/:id',
                templateUrl: function ($stateParams) {
                    return 'modules/content/' + $stateParams.contentType.replace(/_/g, '-') + '-detail.tmpl.html';
                },
                controllerProvider: function ($stateParams) {
                    var returnStr = $stateParams.contentType.replace(/_/g, '-').replace(/-/g, ' ');
                    returnStr = returnStr.replace(/\w\S*/g, function (txt) {
                        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                    });
                    returnStr = returnStr.replace(/ /g, '');
                    return returnStr + 'DetailController as vm';
                },
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    contentType: function ($stateParams) {
                        return $stateParams.contentType;
                    }
                },
                params: {
                    activeTab: null,
                    copy: false,
                    isDeeplink: false
                }
            })
            .state('storm.admin-content-detail-recognition', {
                url: '/content/:contentType/:id/recognition',
                templateUrl: function ($stateParams) {
                    return 'modules/content/content-detail-recognition.tmpl.html';
                },
                controller: 'ContentDetailRecognitionController as vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                abstract: true,
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    content_type: function ($stateParams) {
                        return $stateParams.contentType;
                    }
                }
            })
            .state('storm.admin-content-detail-recognition.section', {
                url: '/:section',
                templateUrl: function ($stateParams) {
                    return CONTENT_RECOGNITION_META[$stateParams.contentType].sections[$stateParams.section || 'qrcode'].template;
                },
                controllerProvider: function ($stateParams) {
                    return CONTENT_RECOGNITION_META[$stateParams.contentType].sections[$stateParams.section || 'qrcode'].controller;
                },
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    content_type: function ($stateParams) {
                        return $stateParams.contentType;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-content-detail-insights', {
                url: '/content/:contentType/:id/insights',
                templateUrl: function ($stateParams) {
                    return 'modules/content/content-detail-insights.tmpl.html';
                },
                controller: 'ContentDetailInsightController as vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                abstract: true,
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    content_type: function ($stateParams) {
                        return $stateParams.contentType;
                    }
                }
            })
            .state('storm.admin-content-detail-insights.section', {
                url: '/:section',
                templateUrl: function ($stateParams) {
                    return CONTENT_INSIGHT_META[$stateParams.contentType].sections[$stateParams.section || 'overview'].template;
                },
                controllerProvider: function ($stateParams) {
                    return CONTENT_INSIGHT_META[$stateParams.contentType].sections[$stateParams.section || 'overview'].controller;
                },
                controllerAs: 'vm',
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    },
                    search: null
                },
                resolve: {
                    id: function ($stateParams) {
                        return $stateParams.id;
                    },
                    content_type: function ($stateParams) {
                        return $stateParams.contentType;
                    },
                    pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {
                        return {
                            page: PaginationUtil.parsePage($stateParams.page),
                            sort: $stateParams.sort,
                            predicate: PaginationUtil.parsePredicate($stateParams.sort),
                            ascending: PaginationUtil.parseAscending($stateParams.sort),
                            search: $stateParams.search
                        };
                    }]
                }
            })
            .state('storm.admin-content-fields', {
                url: '/content/meta-data',
                templateUrl: 'modules/content/content-metadata.tmpl.html',
                // set the controller to load for this page
                controller: 'ContentMetaDataController',
                controllerAs: 'vm',
                // layout-column class added to make footer move to
                // bottom of the page on short pages
                data: {
                    layout: {
                        contentClass: 'layout-column',
                        footer: false,
                        showToolbar: false
                    }
                },
                resolve: {
                    id: function ($stateParams) {
                        return 'content';
                    }
                }
            });
    }
})();
