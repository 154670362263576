(function () {
    'use strict';

    angular
        .module('atheer.user')
        .controller('ProfilePasswordController', ProfilePasswordController);

    /* @ngInject */
    function ProfilePasswordController($q, Principal, User, Feature, JhiLanguageService, triSettings, $filter, id, ToastService) {
        var vm = this;

        vm.changePassword = changePassword;

        vm.useDarkBackGroundLogos = true;
        vm.doNotMatch = null;
        vm.error = null;
        vm.success = null;
        vm.user = null;
        vm.currentPassword = null;
        vm.isAdminMode = id != 'me';
        vm.minLength = 8;
        vm.maxLength = 50;
        vm.featureForcedPassword = Feature.isActive('feature_user_initial_login_forced_password_required');
        vm.forcedPasswordEnabled = false;
        vm.isPublicUser = false;
        vm.passwordVisibility = {
            newPassword: false,
            confirm: false
        };

        vm.userId = id == 'me' ? Principal.getUserId() : id;
        vm.title = $filter('translate')('home.changePassword');

        vm.loadData = function () {
            vm.loadingData = true;
            vm.useDarkBackGroundLogos = triSettings.getSkinModel().use_dark_logos;

            $q.all([
                User.getById({
                    id: vm.userId
                }).$promise
            ]).then(function (data) {
                vm.user = data[0];
                vm.loadingData = false;

            }).catch(function (data) { }).finally(function () { });

            JhiLanguageService.getAll().then(function (languages) {
                vm.languages = languages;
            });
        }

        function checkPasswordReq() {
            if (vm.password == null || vm.confirmPassword == null) {
                return false;
            }

            var pattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,50}$/;
            if (!vm.password.match(pattern)) {
                ToastService.displayToast($filter('triTranslate')('atheer.password.messages.doesntMeetReq'), 2000);
                return false;
            }

            if (vm.password !== vm.confirmPassword) {
                ToastService.displayToast($filter('triTranslate')('atheer.password.messages.dontMatch'), 2000);
                return false;
            }

            if (vm.password.length < vm.minLength || vm.password.length > vm.maxLength) {
                return false;
            }
            return true;
        }

        function changePassword() {
            if (!checkPasswordReq()) {
                vm.error = null;
                vm.success = null;
                vm.doNotMatch = 'ERROR';
            } else {

                if (vm.isAdminMode) {
                    changeUserPassword();
                } else {
                    changeMePassword();
                }
            }
        }

        function changeMePassword() {
            vm.doNotMatch = null;

            var passwordInfo = {
                current_password: vm.currentPassword,
                new_password: vm.password
            }

            var promise = User.changeMePassword(passwordInfo).$promise;

            promise.then(function () {
                vm.error = null;
                vm.success = 'OK';

                ToastService.displayToast($filter('triTranslate')('atheer.password.messages.success'), 2000)
                vm.currentPassword = null;
            }).catch(function () {
                vm.success = null;
                vm.error = 'ERROR';

                ToastService.displayToast($filter('triTranslate')('atheer.password.messages.incorrect'), 2000);
            });
        }
        vm.togglePasswordVisibility = function(field) {
            vm.passwordVisibility[field] = !vm.passwordVisibility[field];
        };
        function changeUserPassword() {
            vm.doNotMatch = null;

            var passwordInfo = {
                invitation_key: null,
                notify_user: false,
                password: vm.password,
                forced_password_enabled: vm.forcedPasswordEnabled
            };

            var promise = User.changeUserPassword({
                id: vm.userId
            }, passwordInfo).$promise;

            promise.then(function () {
                vm.error = null;
                vm.success = 'OK';

                ToastService.displayToast($filter('triTranslate')('atheer.password.messages.success'), 2000);
                vm.currentPassword = null;
            }).catch(function () {
                vm.success = null;
                vm.error = 'ERROR';

                ToastService.displayToast($filter('triTranslate')('atheer.password.messages.incorrect'), 2000);
            });
        }
        vm.loadData();
    }
})();
