(function() {
    'use strict';

    angular
        .module('atheer.conversation')
        .directive('conversationInfo', conversationInfo);

    function conversationInfo() {
        var directive = {
            scope: {
                conversationModel: '='
            },
            bindToController: true,
            controller: conversationInfoController,
            controllerAs: 'vm',
            templateUrl: 'modules/conversation/conversation-info.tmpl.html',
            restrict: 'E'
        };
        return directive;
    };

    /* @ngInject */
    function conversationInfoController($scope, $mdDialog, $mdSidenav, $location, $mdToast, Message, Content, $document, $mdPanel, $state, $timeout, $q, $filter, ParseLinks, Principal, Conversation, AlertService, triSettings) {
        var vm = this;

        vm.primaryColor = triSettings.getSkinModel().primary_color_hex;

        vm.loadingData = false;
        vm.showConversationDetail = true;
        vm.showPinned = false;
        vm.showSharedContent = false;
        vm.showSharedFiles = false;
        vm.showMembers = false;
        vm.groupName = null;
        vm.purpose = null;
        vm.pinnedMessages = [];
        vm.contentMessageIds = [];
        vm.contentMessages = [];
        vm.userInfo = [];
        vm.conversationId = vm.conversationModel.id;

        vm.getUserImageUrl = function(user_info) {
            return user_info.picture_id ? '/media/pictures/' + user_info.picture_id : 'assets/images/avatars/user-avatar.png';
        };

        vm.close = function() {
            // firing an event downwards
            $scope.$emit('conversationMessageInternal', {
                type: 'CLOSE_CONVERSATION_INFO'
            });
        };

        vm.checkForUpdate = function() {
            if (vm.conversationId != vm.conversationModel.id) {
                vm.conversationId = vm.conversationModel.id;
                vm.groupName = null;
                vm.purpose = null;
                vm.pinnedMessages = [];
                vm.contentMessageIds = [];
                vm.contentMessages = [];
                vm.userInfo = [];
                loadData();
            }

            return true;
        }

        vm.editConversation = function() {
            // firing an event downwards
            $scope.$emit('conversationMessageInternal', {
                type: 'EDIT_CONVERSATION'
            });
        };

        vm.inviteUsersToConversation = function() {
            // firing an event downwards
            $scope.$emit('conversationMessageInternal', {
                type: 'CONVERSATION_ADD_USERS'
            });
        };

        function transformMessage(message) {
            if (vm.conversationModel.type == 'PRIVATE_USER') {
                if (message.publisher_id == vm.userId) {
                    message.user_info = {
                        publisher_name: Principal.getUserInfo().first_name + ' ' + Principal.getUserInfo().last_name
                    };
                } else {
                    message.user_info = {
                        publisher_name: vm.conversationModel.user_info.first_name + ' ' + vm.conversationModel.user_info.last_name
                    };
                }
            } else {
                var userInfo = $filter('filter')(vm.conversationModel.users_info, {
                    id: message.publisher_id
                })[0];
                message.user_info = {
                    publisher_name: userInfo.first_name + ' ' + userInfo.last_name
                };
            }

            return message;
        };

        function updateContentMessages() {
            if (vm.contentMessageIds.length > 0) {
                Content.getConversationContents({
                    query: 'id=in=(' + vm.contentMessageIds + ')',
                }, function(response, headers) {
                    response.forEach(function(item) {
                        vm.contentMessages.push(item);
                    })
                })
            }
        };

        function updateUserInfo() {
            if (vm.conversationModel.type != "PRIVATE_USER") {
                var currentIds = [];
                for (var i = 0; i < vm.conversationModel.users_info.length; i++) {
                    for (var j = 0; j < vm.conversationModel.users.length; j++) {
                        if (vm.conversationModel.users[j].user_id ==  vm.conversationModel.users_info[i].id && !vm.conversationModel.users[j].left) {
                            var exist = false;
                            for (var k = 0; k < currentIds.length; k++) {
                                if (currentIds[k] == vm.conversationModel.users_info[i].id) {
                                    exist = true;
                                    break;
                                }
                            }
                            if (!exist) {
                                vm.userInfo.push(vm.conversationModel.users_info[i])
                                currentIds.push(vm.conversationModel.users_info[i].id)
                            }
                        }
                    }
                }
            }
        }

        function loadData() {
            vm.loadingData = true;
            $q.all([
                    Message.getPinned({
                        query: "conversationId==" + vm.conversationModel.id,
                        sort: "created_on,desc",
                    }).$promise,
                    Message.get({
                        query: "conversationId==" + vm.conversationModel.id + ";type==CONTENT",
                        sort: "created_on,desc",
                    }).$promise
                ])
                .then(function(results) {
                    angular.forEach(results[0], function(message) {
                        vm.pinnedMessages.push(transformMessage(message));
                    });
                    angular.forEach(results[1], function(message) {
                        vm.contentMessageIds.push(message.body);
                    });
                    angular.forEach(vm.pinnedMessages, function(message) {
                        angular.forEach(results[1], function(contentMessage) {
                            if(message.id === contentMessage.id) {
                                message.content = contentMessage.content;
                            }
                        });
                    });
                    updateContentMessages();
                    updateUserInfo();
                    vm.loadingData = false;
                });
        };

        loadData();
    };
})();
