(function() {
    'use strict';

    angular
        .module('atheer.core')
        .controller('ConversationLocalContentPicker', ConversationLocalContentPicker);

    /* @ngInject */
    function ConversationLocalContentPicker($scope, $mdDialog, Content, Upload, $timeout, $filter, ToastService, annotationMode, $q) {
        var vm = this;

        vm.uploadPercentage = 0.0;
        vm.uploadStatus = 'idle';
        vm.processStatus = 'idle';
        vm.uploadEvt = [];

        vm.files = [];
        vm.fileType = '';
        vm.filterText = '';
        vm.tempFiles = [];

        vm.filePattern = "image/*,video/*,audio/*,application/pdf,application/zip,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/x-tar,application/gzip,.rar,text/plain,text/csv,application/rtf,.gzip";
        vm.acceptPattern = ".jpg, .png, .jpeg, .gif, .tiff, .avif, .bmp, .webp, .mp4, .mov, .webm, .avi, .wmv, .mp3, .wav, .pdf, .doc, .docx, .xls, .xlsx, .ppt, .pptx, .rtf, .txt, .csv, .zip, .tar, .gz, .rar, .gzip";
        vm.fileMaxSizes = {
            "audio": "524288000",
            "video": "524288000",
            "image": "524288000",
            "zip": "524288000",
            "pdf": "524288000",
            "doc": "524288000",      
            "docx": "524288000",
            "xls": "524288000",
            "xlsx": "524288000",
            "ppt": "524288000",
            "pptx": "524288000",
            "x-tar": "524288000",  
            "gzip": "524288000",
            "x-rar-compressed": "524288000",
            "text": "524288000",
            "csv": "524288000",
            "rtf": "524288000"
        };

        vm.upload = function(files, file, newFiles, duplicateFiles, invalidFiles) {
            if(invalidFiles && invalidFiles.length > 0) {
                if (invalidFiles[0].$error == 'pattern') {
                    displayToast(invalidFiles[0].name + $filter('triTranslate')('localContent.invalidPattern'));
                }
            } else if (files && files.length > 0){
                vm.files = files;
                const fileType = files[0].type.split("/");
                if (fileType[0] === "application" || fileType[0] === "text") {
                    if (
                        [
                            "pdf",
                            "msword",
                            "vnd.openxmlformats-officedocument.wordprocessingml.document",
                            "vnd.ms-excel",
                            "vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                            "vnd.ms-powerpoint",
                            "vnd.openxmlformats-officedocument.presentationml.presentation",
                            "rtf",
                            "plain",
                            "csv",
                        ].includes(fileType[1])
                    ) {
                        vm.fileType = "document";
                    } else if (
                        ["zip", "x-tar", "gzip", "vnd.rar"].includes(
                            fileType[1]
                        )
                    ) {
                        vm.fileType = "zip";
                    }
                } else {
                    vm.fileType = fileType[0];
                }

                if (vm.fileType === "" || vm.fileType === "application/octet-stream") {
                    const fileName = files[0].name;
                    const extension = fileName.split('.').pop().toLowerCase();
                    if (["gz", "gzip", "rar"].includes(extension)) {
                        vm.fileType = "zip";
                    }
                }

                if(annotationMode && vm.fileType != 'image') {
                    displayToast(files[0].name + 'is not an image type');
                    return;
                }
                if(files[0].size > vm.fileMaxSizes[vm.fileType]) {
                    displayToast(files[0].name + $filter('triTranslate')('localContent.tooBig' ));
                } else {
                    var canceler = $q.defer();
                    vm.uploadStatus = 'uploading';
                    vm.files[0].cancelEvt = canceler;
                    Upload.upload({
                        url: 'api/media/upload',
                        data: {
                            'file': files[0],
                            'type': vm.fileType
                        },
                        timeout: canceler.promise
                    }).then(function(resp) {
                        if(vm.uploadStatus != 'stop') {
                            vm.uploadStatus = 'complete';
                            vm.processStatus = 'uploading';
                            uploadContent(resp.data, vm.fileType);
                        }
                    }, function(err) {
                        if (err && err.status !== -1) {
                            vm.uploadStatus = 'error'
                        }
                    }, function(evt) {
                        vm.files[0].uploadEvt = evt;
                        vm.uploadStatus = 'uploading';
                        vm.uploadPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    });
                }
            }
        };

        vm.stopUploading = function(file) {
            file.cancelEvt.resolve();
            vm.uploadStatus = 'idle';
            vm.processStatus = 'idle';
        };

        vm.uploadMore = function() {
            vm.uploadStatus = 'idle';
            vm.processStatus = 'idle';
        };

        vm.onChange = function() {
            if(vm.tempFiles.length == 0) {
                vm.tempFiles = vm.files;
                vm.files = vm.filterText ? vm.files.filter(function(file) {
                    return file.name.indexOf(vm.filterText) >= 0;
                }): vm.files;
            } else {
                vm.files = vm.filterText ? vm.tempFiles.filter(function(file) {
                    return file.name.indexOf(vm.filterText) >= 0;
                }): vm.tempFiles;
            }
        }

        vm.close = function() {
            $mdDialog.cancel();
        };

        function bytesToSize(bytes) {
            var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes == 0) return '0 Byte';
            var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
            return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
        };

        function displayToast(message) {
            ToastService.displayToast(message, 3000);
        };

        function uploadContent(content, type) {
            var uploadContent = {
                title: content.name,
                description: content.name,
                status: 'PRIVATE',
                data: {
                    type: type == 'audio' ? 'podcast' : type,
                    source_media: content
                }
            }
            Content.localContentShare({}, uploadContent, function(result) {
                vm.processStatus = 'complete';
                $timeout(function() {
                    $mdDialog.hide(result);
                }, 3000);
            }, function(err) {
                vm.processStatus = 'error';
                $timeout(function() {
                    $mdDialog.cancel();
                }, 3000);
            });
        };
    }
})();
