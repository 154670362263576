(function() {
    'use strict';

    angular
        .module('atheer.system')
        .controller('AirformSettingController', AirformSettingController)

    /* @ngInject */
    function AirformSettingController($mdDialog, $q, $scope, $filter, $sce, ParseLinks, $state, AlertService, ContentAction, $mdToast, $mdSidenav, Principal, Setting, Feature, ToastService) {
        var vm = this;

        vm.photo_header_max_size = 1;
        vm.video_header_max_size = 5;
        vm.pageTitleKey = $state.$current.data.pageTitle;

        vm.airformModel = {
            id: null,
            photo_header_max_size: 1,
            video_header_max_size: 5
        };

        vm.onSaveSuccess = function(result) {
            vm.airformModel = result;
            vm.isSaving = false;
            AlertService.displayToast($mdToast);
        };

        vm.onSaveError = function() {
            vm.isSaving = false;
        };

        function setAirformSettings() {
            setAirformSettingField("photo_header_max_size", vm.airformModel.photo_header_max_size);
            setAirformSettingField("video_header_max_size", vm.airformModel.video_header_max_size);
        }

        function setAirformSettingField(fieldName, fieldValue) {
            var foundField = false;
            angular.forEach(vm.airformSetting.fields, function(settingField) {
                if (settingField.name == fieldName) {
                    settingField.value = fieldValue;
                    foundField = true;
                }
            });

            if (!foundField) {
                var settingField = {
                    name: fieldName,
                    value: fieldValue,
                }
                vm.airformSetting.fields.push(settingField);
            }
        }

        vm.onNumberInputKeydown = function(e) {
            if(["-","+","e","."].includes(e.key)) {                
                e.stopPropagation();
                e.preventDefault();
            }
        }

        vm.save = function() {
            if($scope.airformSettingsForm.$invalid) {
                return;
            }
            setAirformSettings();

            Setting.update(vm.airformSetting, function() {
                ToastService.displayToast($filter('triTranslate')("atheer.setting.success"), 3000);
            });
        }



        vm.loadData = function() {
            vm.loadingData = true;

            $q.all([
                    Setting.getAirformSettings().$promise
                ])
                .then(function(results) {
                    setAirformSessionModel(results[0]);
                    vm.loadingData = false;
                });
        };

        function setAirformSessionModel(settings) {

            angular.forEach(settings, function(setting) {
                if (setting.category == 'AiRForm') {
                    vm.airformSetting = setting;

                    angular.forEach(setting.fields, function(settingField) {
                        if (settingField.name == 'photo_header_max_size') {
                            vm.airformModel.photo_header_max_size = parseInt(settingField.value);
                        }
                        if (settingField.name == 'video_header_max_size') {
                            vm.airformModel.video_header_max_size = parseInt(settingField.value);
                        }

                    });
                }
            });
        };

        vm.loadData();
    }
})();
