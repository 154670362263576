(function () {
    'use strict';

    angular
        .module('atheer.content')
        .directive('fpContentFeedItem', fpContentFeedItem);

    function fpContentFeedItem() {
        var directive = {
            restrict: 'E',
            scope: {
                'content': '=',
                'type': '=',
                'publisher': '=',
                'enableRemove': '@?',
                'showDescription': '@?',
                'showDuration': '@?',
                'removeCallback': '&?',
                'messageView': '@?',
            },
            transclude: true,
            controller: contentListItemViewDirectiveController,
            templateUrl: 'modules/content/content-feed-item.tmpl.html'
        };
        return directive;
    }

    /* @ngInject */
    function contentListItemViewDirectiveController($rootScope, $scope, $state, CONTENT_META, $q, Principal, Experience, $filter, ContentAction, Feature, $mdDialog, Launcher) {

        $scope.userId = Principal.getUserId();
        $scope.showDescription = $scope.showDescription == 'true';
        $scope.showDuration = $scope.showDuration == 'true';
        $scope.messageView = $scope.messageView == 'false';

        $scope.contentMetaData = [];

        function init() {
            angular.forEach(CONTENT_META, function (metaData) {
                var iconData = {
                    type: metaData.default_model.data.type,
                    icon: metaData.icon
                }
                $scope.contentMetaData.push(iconData);
            });
        };

        $scope.isActive = function (feature) {
            return Feature.isActive(feature);
        };

        $scope.getMediaUrl = function (mediaUrl) {
            return $filter('mediaUrl')(mediaUrl);
        };

        $scope.getCardProperty = function (type) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: type.replace(/_/g, '-')
            })[0];
            return contentMetaData.card_properties;
        };

        $scope.getDefaultBackground = function (type) {
            var card_properties = $scope.getCardProperty(type);
            return card_properties != undefined ? card_properties.defaultBackground : '';
        };

        $scope.getDefaultIcon = function (type) {
            var card_properties = $scope.getCardProperty(type);
            return card_properties != undefined ? card_properties.defaultIcon : '';
        };

        $scope.isLiked = function (content) {
            return $scope.currentContentLikeData[content.id] ? true : false;
        };

        $scope.getCardMeta = function (card) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: card.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData;
        };

        $scope.launchContent = function (content) {
            if ($scope.fromSeries && $scope.seriesEnrolled) {
                Launcher.getAssignment({
                    id: content.id
                }, function (result) {
                    ContentAction.launchContent(content);
                });
            } else if ($scope.fromSeries && !$scope.seriesEnrolled) {
                ContentAction.previewContent(content);
            } else {
                ContentAction.launchContent(content);
            };
        };

        init();
    }
})();
