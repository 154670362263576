(function() {
    'use strict';

    angular
        .module('atheer.content')
        .controller('AnnouncementDetailController', AnnouncementDetailController)
        .controller('AnnouncementViewController', AnnouncementViewController)

    /* @ngInject */
    function AnnouncementViewController($scope, $controller) {

        var basectrl = $controller('BaseContentViewController as vm', {
            $scope: $scope
        });
        angular.extend(this, basectrl);

        $scope.getImageSrc = function() {
            return $scope.showMedia() && $scope.data.media_id ? '/media/images/' + $scope.data.media_id : 'assets/images/card/announcement.svg';
        }

        $scope.getTitleHeight = function() {
            return document.getElementsByClassName('title')[0].clientHeight;
        }

        $scope.getTitleColor = function() {
            if($scope.data.data.title_color && $scope.data.data.title_color != null) {
                return $scope.data.data.title_color;
            } else {
                return '#343A3A';
            }
        };

        $scope.getContentColor = function() {
            if($scope.data.data.content_color && $scope.data.data.content_color != null) {
                return $scope.data.data.content_color;
            } else {
                return '#343A3A';
            }
        };
    }

    /* @ngInject */
    function AnnouncementDetailController($scope, $controller, id, $filter) {

        var basectrl = $controller('BaseContentDetailController as vm', {
            $scope: $scope,
            id:id,
            content_type: "announcement"
        });
        angular.extend(this, basectrl);

        var vm = this;

        $scope.descriptionLimitExceed = false;

        vm.froalaOptions = {
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'save', 'table', 'url', 'video'],
            toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', 'insertLink'],
            toolbarButtonsMD: ['bold', 'italic', 'underline', 'strikeThrough', 'insertLink'],
            toolbarButtonsSM: ['bold', 'italic', 'underline', 'strikeThrough', 'insertLink'],
            toolbarButtonsXS: ['bold', 'italic', 'underline', 'strikeThrough', 'insertLink'],
            charCounterMax: 255,
            placeholderText: $filter('translate')('atheer.content.announcementsView.content'),
            events: {
                "charCounter.update": function (e) {
                    if (this.el.textContent.length > 255) {
                        $scope.descriptionLimitExceed = true;
                    } else {
                        $scope.descriptionLimitExceed = false;
                    }
                    $scope.$apply();
                },
                "paste.before": function (e) {
                    var pastedPlainData = e.clipboardData.getData("text/plain");
                    this.html.insert(pastedPlainData);
                    this.undo.saveStep();
                    $scope.$apply();
                    return false;
                },
            },
        };
    }
})();
