  (function() {
      'use strict';
      angular
          .module('atheer.services')
          .factory('User', User);


      /* @ngInject */
      function User($resource, DateUtils) {
          var resourceUrl = 'api/users/:id';

          return $resource(resourceUrl, {
              id: '@id'
          }, {
              'query': {
                  method: 'GET',
                  isArray: true
              },
              'get': {
                  method: 'GET',
                  transformResponse: function(result) {
                      return transformResponse(result);
                  }
              },
              'getById': {
                  url: 'api/users/:id?id=true',
                  method: 'GET',
                  transformResponse: function(result) {
                      return transformResponse(result);
                  }
              },
              'getWithPermission': {
                  url: 'api/users/:id?permissions=true',
                  method: 'GET',
              },
              'getFullUser': {
                  url: 'api/users/:id?permissions=true&include-audiences=false&widget-auth=false&update-visit=false',
                  method: 'GET',
              },
              'changeMePassword': {
                  url: 'api/users/me/change_password',
                  method: 'POST'
              },
              'forceMePassword': {
                url: 'api/users/me/force_password',
                method: 'POST'
            },
              'changeUserPassword': {
                  url: 'api/users/:id/change_password',
                  method: 'POST'
              },
              'passwordResetInit': {
                  url: 'api/users/reset_password/init',
                  method: 'POST'
              },
              'passwordResetFinish': {
                  url: 'api/users/reset_password/finish',
                  method: 'POST'
              },
              'passwordResetTemp': {
                  url: 'api/users/reset_password/temp',
                  method: 'POST'
              },
              'validateImportFile': {
                  url: 'api/users/import/validate',
                  method: 'GET',
              },
              'validateImportExcelFile': {
                url: 'api/users/excel/validate',
                method: 'GET',
              },
              'importUsers': {
                  url: 'api/users/import',
                  method: 'POST',
              },
              'getImportStatus': {
                  url: 'api/system/job-streams/:id',
                  method: 'GET'
              },
              'importUsersFromSpreadSheet': {
                url: 'api/users/spreadsheet/upload/media',
                method: 'POST',
              },
              'getSpreadSheetImportStatus': {
                url: 'api/users/spreadsheet/:id',
                method: 'GET',
              },
              'getPersonalSession': {
                  url: 'api/users/me/session',
                  method: 'GET'
              },
              'getConversations': {
                  url: 'api/users/me/conversations',
                  method: 'GET',
                  isArray: true
              },
              'getQRCode': {
                  url: 'api/users/:id/qrcode',
                  method: 'GET'
              },
              'save': {
                  url: 'api/users',
                  method: 'POST',
                  transformResponse: function(result) {
                      return transformResponse(result);
                  }
              },
              'update': {
                  method: 'PUT',
                  transformResponse: function(result) {
                      return transformResponse(result);
                  }
              },
              'updateme': {
                url: 'api/users/me',
                  method: 'PUT',
                  transformResponse: function(result) {
                      return transformResponse(result);
                  }
              },
              'delete': {
                  method: 'DELETE'
              },
              'activate': {
                  url: 'api/users/activate',
                  method: 'GET',
                  params: {},
                  isArray: false
              },
              'signup': {
                  url: 'api/users/signup',
                  method: 'POST'
              },
              'register': {
                  url: 'api/users/register',
                  method: 'POST'
              },
              'checkLogin': {
                  url: 'api/users/check-login/:login',
                  method: 'GET',
                  transformResponse: function(data) {
                      if (data) {
                          data = angular.fromJson(data);
                          return data;
                      } else {
                          return null;
                      }
                  }
              },
              'validateDeviceId': {
                  url: 'api/users/guest/validate/:deviceId',
                  method: 'GET',
                  isArray: false
              },
              'getJobs': {
                  url: resourceUrl + '/recent-applets',
                  method: 'GET',
                  isArray: true
              },
              'bulkRemove': {
                  url: resourceUrl + 'users/bulk/remove',
                  method: 'POST'
              },
          });

          function transformResponse(result) {
              if(result === "") {
                  return result;
              }
              result = angular.fromJson(result);

              if (result.start_date) {
                  result.start_date = DateUtils.convertDateTimeFromServer(result.start_date);
              }

              if (result.terminated_on) {
                  result.terminated_on = DateUtils.convertDateTimeFromServer(result.terminated_on);
              }

              if (result.properties) {
                  var keys = Object.keys(result.properties);
                  angular.forEach(keys, function(key) {
                      if(key.endsWith("_at")) {
                          result.properties[key] = DateUtils.convertDateTimeFromServer(result.properties[key]);
                      }
                  });
              }
              return result;
          }
      }
  })();
