(function () {
    'use strict';

    angular
        .module('atheer.topic')
        .controller('TopicDetailController', TopicDetailController)

    /* @ngInject */
    function TopicDetailController($rootScope, $scope, $mdDialog, $filter, $q, id, MetaData, Topic, ContentAction, CONTENT_META, $state, AlertService, $mdToast, $mdSidenav, Content, Team, AirForm, User, ParseLinks, $timeout, pagingParams, paginationConstants, $stateParams, $window, ToastService) {
        var vm = this;

        var FORMLY_ATHEER_DATA_TYPE_MAPPING = {
            'BOOLEAN': 'boolean-switch',
            'STRING': 'input',
            'LIST': 'custom-select',
            'DATE': 'custom-datepicker',
            'DATETIME': 'datepicker',
            'NUMBER': 'input',
            'DECIMAL': 'input',
            'OBJECT': 'objectpicker',
            'ARRAY': 'custom-chips'
        };

        var FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING = {
            'STRING': 'text',
            'NUMBER': 'number',
            'DECIMAL': 'number'
        };

        vm.loadingData = false;
        vm.topicDataModel = null;
        vm.oldTopicDataModel = null;
        vm.audiences = [];
        vm.parentTopic = null;
        vm.topicType = 'STATIC';
        vm.tabView = 'SUB-TOPIC';
        vm.pageContent = null;
        vm.subTopics = [];
        vm.contents = [];
        vm.airForms = [];
        vm.users = [];
        vm.old_subTopics = [];
        vm.old_contents = [];
        vm.old_airForms = [];
        vm.old_users = [];
        vm.owner = null;
        vm.loadingContent = false;
        vm.loadingAirForms = false;
        vm.loadingUsers = false;
        vm.loadingSubTopics = false;
        vm.subTopicsPage = 1;
        vm.contentsPage = 1;
        vm.isSubTopic = $stateParams.isSubTopic;
        vm.illustartionEmptyScreenIdContent = "no_content_smartscan";
        vm.illustartionEmptyScreenIdSmartScan = "no_airform_smartscan";
        vm.illustartionEmptyScreenIdUser = "no_user_smartscan";
        vm.isHovered = false;
        vm.topicMeta = null;
        vm.topicMetaFields = [];

        if (angular.isDefined($stateParams.topicType) && $stateParams.topicType != null) {
            vm.topicType = $stateParams.topicType;
        };

        vm.query = {
            filter: '',
            limit: paginationConstants.itemsPerPage,
            page: 1
        };

        vm.getQueryString = function (criteria) {
            if (criteria.length <= 0) {
                return null;
            } else {
                return 'name=re=' + criteria;
            }
        };

        vm.searchTeams = function (criteria) {
            var d = $q.defer();
            var result = Team.query({
                query: vm.getQueryString(criteria)
            }, function (result) {
                d.resolve(result);
            });
            return d.promise;
        };

        vm.backButtonClickHandler = function () {
            if (vm.isNew()) {
                vm.oldTopicDataModel.custom_attributes = angular.copy(
                    vm.topicDataModel.custom_attributes
                );
            }
            if (
                !$rootScope._.isEqual(
                    vm.topicDataModel,
                    vm.oldTopicDataModel
                ) ||
                !vm.isArrayEqual(vm.old_subTopics, vm.subTopics) ||
                !vm.isArrayEqual(vm.old_airForms, vm.airForms) ||
                !vm.isArrayEqual(vm.old_users, vm.users) ||
                !vm.isArrayEqual(vm.old_contents, vm.contents)
            ) {
                var success_callback = function () {
                    $state.go("storm.admin-topics");
                };
                getModal(success_callback, "");
            } else {
                $state.go("storm.admin-topics");
            }
        };

        vm.saveContent = function (contentIds) {
            Topic.assignContents(
                {
                    id: vm.topicDataModel.id,
                },
                contentIds,
                onSaveSuccess,
                onSaveError
            );

            function onSaveSuccess(result) {
                vm.loadContents(vm.topicDataModel.id);
            }

            function onSaveError(err) {
                console.error(err);
            }
        };

        vm.removeContentData = function (contentIds) {
            Topic.removeContents(
                {
                    id: vm.topicDataModel.id,
                },
                contentIds,
                onSaveSuccess,
                onSaveError
            );
            function onSaveSuccess(result) {
                vm.loadContents(vm.topicDataModel.id);
            }

            function onSaveError() {
                console.error(err);
            }
        };

        vm.loadParentTopic = function (parentId) {
            Topic.get({
                id: parentId
            }, function (result, headers) {
                vm.parentTopic = result;
            });
        };

        vm.openTopicPicker = function () {
            var sTopics = [];
            if (!vm.topicDataModel.sub_topics) {
                vm.topicDataModel.sub_topics = [];
            }
            sTopics = vm.topicDataModel.sub_topics;
            $mdDialog.show({
                multiple: true,
                controller: 'TopicPickerController',
                templateUrl: 'modules/topic/topic-picker.tmpl.html',
                controllerAs: 'vm',
                locals: {
                    filter: 'id!=' + vm.topicDataModel.id,
                    allowMultiSelect: true,
                    topics: sTopics
                }
            })
                .then(function (selectedItems) {
                    var subTopicIds = [];
                    subTopicIds = selectedItems;

                    if (angular.isUndefined(vm.topicDataModel.sub_topics)) {
                        vm.topicDataModel.sub_topics = subTopicIds;
                    } else {
                        angular.forEach(subTopicIds, function (subTopicId) {
                            if (
                                vm.topicDataModel.sub_topics.indexOf(
                                    subTopicId.id
                                ) == -1
                            ) {
                                vm.topicDataModel.sub_topics.push(
                                    subTopicId.id
                                );
                            }
                        });
                    }
                    if (selectedItems.length) {
                        vm.subTopics = vm.subTopics.concat(selectedItems);
                    }
                })
                .finally(function () {
                    vm.subTopicsPage = 1;
                 });
        };

        vm.isArrayEqual = function (arr1, arr2, key) {
            if (arr1.length !== arr2.length) {
                return false;
            }
            var hashmap = {};
            arr1.map(function (item) {
                hashmap[item[key]] = 1;
            });
            return (
                arr2.filter(function (item) {
                    return hashmap.hasOwnProperty(item[key]);
                }).length === arr1.length
            );
        };

        vm.getTopicThumbnail = function (topic) {
            if (topic.use_custom_thumbnail && !!topic.thumbnail_url) {
                return {
                    "background-image":
                        "url(/media/images/" + topic.thumbnail_url + ")",
                };
            }
            return {
                "background-image":
                    "url(/assets/images/topic/no_topic-preview.svg)",
                "background-position": "center",
                "background-size": "auto",
            };
        };

        vm.removeSubTopic = function (subTopic) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.topic.removeSubTopic'))
                .ariaLabel($filter('translate')('atheer.topic.removeSubTopic'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                var index = vm.topicDataModel.sub_topics.indexOf(subTopic.id);
                vm.topicDataModel.sub_topics.splice(index, 1);
                var sIndex = vm.subTopics.findIndex(function (sub_topic) {
                    return subTopic.id === sub_topic.id;
                });
                if (sIndex !== -1) {
                    vm.subTopics.splice(sIndex, 1);
                }              
            }).finally(function () {
             });
        };

        vm.loadPageContent = function (contentId) {
            Content.get({
                id: contentId
            }, function (result, headers) {
                vm.pageContent = result;
            });
        };

        vm.loadOwner = function () {
            Team.get({
                id: vm.topicDataModel.owner_id
            }, function (result, headers) {
                vm.owner = result;
            });
        };

        vm.getSubTopic = function (topicId) {
            $state.go("storm.admin-topic-detail", {
                'id': topicId
            });
        };

        vm.loadContents = function (topicId) {
            vm.loadingContent = true;
            Topic.getContents({
                id: topicId
            }, function (result, headers) {
                vm.contents = angular.copy(result);
                vm.old_contents = angular.copy(result);
                vm.loadingContent = false;
                vm.contentsPage = pagingParams.page;
                vm.contentsQueryCount = headers('X-Total-Count');
            });
        };

        vm.loadSubTopics = function (topicId) {
            vm.loadingSubTopics = true;
            Topic.getSubTopics({
                id: topicId,
                page: vm.subTopicsPage - 1,
                size: paginationConstants.itemsPerPage,
            }, function (result, headers) {
                if(!!vm.topicDataModel.sub_topics) {
                    vm.topicDataModel.sub_topics = angular.copy(vm.oldTopicDataModel.sub_topics);
                }
                vm.subTopics = angular.copy(result);
                vm.old_subTopics = angular.copy(result);
                vm.loadingSubTopics = false;
                vm.subTopicsQueryCount = headers('X-Total-Count');
            });
        };

        vm.loadAirForms = function () {
            vm.loadingAirForms = true;
            if (angular.isDefined(vm.topicDataModel.air_forms) && vm.topicDataModel.air_forms.length > 0) {
                AirForm.query({
                    page: 0,
                    size: 50,
                    sort: 'name,asc',
                    query: 'id=in=(' + vm.topicDataModel.air_forms + ');status==ACTIVE'
                }, function (data, headers) {
                    vm.airForms = angular.copy(data);
                    vm.old_airForms = angular.copy(data);
                    vm.loadingAirForms = false;
                });
            } else {
                vm.airForms = [];
                vm.loadingAirForms = false;
            };
        };

        vm.loadUsers = function () {
            vm.loadingUsers = true;
            if (angular.isDefined(vm.topicDataModel.users) && vm.topicDataModel.users.length > 0) {
                User.query({
                    page: 0,
                    size: 50,
                    sort: 'first_name,asc',
                    query: 'id=in=(' + vm.topicDataModel.users + ')'
                }, function (data, headers) {
                    vm.users = angular.copy(data);
                    vm.old_users = angular.copy(data);
                    vm.loadingUsers = false;
                });
            } else {
                vm.users = [];
                vm.loadingUsers = false;
            };
        };

        vm.openAirFormPicker = function () {
            var filterQuery = 'status==ACTIVE';
            if (vm.airForms.length) {
                filterQuery = filterQuery + ';id=out=(' + vm.airForms.map(function(airform ){return airform.id }).toString() + ')'
            }
            $mdDialog.show({
                controller: 'AirFormPickerController',
                templateUrl: 'modules/airform/airform-picker.tmpl.html',
                controllerAs: 'vm',
                locals: {
                    filter: filterQuery,
                    allowMultiSelect: true
                }
            })
                .then(function (selectedItems) {
                    var airformIds = [];
                    angular.forEach(selectedItems, function (airform) {
                        airformIds.push(airform.id);
                    });

                    if (angular.isUndefined(vm.topicDataModel.air_forms)) {
                        vm.topicDataModel.air_forms = airformIds;
                    } else {
                        angular.forEach(airformIds, function (airformId) {
                            if (vm.topicDataModel.air_forms.indexOf(airformId) == -1) {
                                vm.topicDataModel.air_forms.push(airformId);
                            }
                        });
                    };
                    vm.airForms = vm.airForms.concat(selectedItems);
                });
        };

        vm.openUserPicker = function () {
            $mdDialog.show({
                templateUrl: 'modules/user/user-picker.tmpl.html',
                controller: 'UserPickerController',
                controllerAs: 'vm',
                locals: {
                    filter: vm.users.map(function(user ){return user.id }).toString(),
                    allowMultiSelect: true,
                    userslist: [],
                    isSession: false,
                    isScheduledSession: false,
                    isAdmin: true,
                    isConversationSession: false,
                    isSmartScan: true
                }
            })
                .then(function (selectedItems) {
                    var userIds = [];
                    angular.forEach(selectedItems, function (user) {
                        userIds.push(user.id);
                    });

                    if (angular.isUndefined(vm.topicDataModel.users)) {
                        vm.topicDataModel.users = userIds;
                    } else {
                        angular.forEach(userIds, function (userId) {
                            if (vm.topicDataModel.users.indexOf(userId) == -1) {
                                vm.topicDataModel.users.push(userId);
                            }
                        });
                    };
                    vm.users = vm.users.concat(selectedItems);
                });
        };

        vm.openContentPicker = function () {
            var contentFilter = vm.contents.length ? 'id=out=('+ vm.contents.map(function(content) { return content.id })+');' : ''
            $mdDialog.show({
                templateUrl: 'modules/content/content-picker.html',
                controller: 'ContentPickerController',
                controllerAs: 'vm',
                locals: {
                    filter: 'embedded==false;type=out=(SERIES,EMAIL_LAYOUT);'+ contentFilter +'meta_type==DISCOVERABLE;status!=ARCHIVED',
                    allowMultiSelect: true,
                    isWeb: false,
                    isNotFilter: false
                }
            })
                .then(function (selectedItems) {
                    var contentIds = [];
                    selectedItems = selectedItems.filter(function (content) {
                        return !vm.contents.filter(function (cont) {
                            return cont.id === content.id;
                        }).length;
                    });
                    angular.forEach(selectedItems, function (selectedItem) {
                        contentIds.push(selectedItem.id);
                    });
                    vm.contents = vm.contents.concat(selectedItems);
                });
        };

        vm.removeContent = function (content) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.topic.removeContent'))
                .ariaLabel($filter('translate')('atheer.topic.removeContent'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                var contentIds = [];
                contentIds.push(content.id);

                var index = vm.contents.indexOf(content.id);
                vm.contents.splice(index, 1);

            }).finally(function () { });
        };

        vm.removeAirForm = function (airform) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.topic.removeAirForm'))
                .ariaLabel($filter('translate')('atheer.topic.removeAirForm'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                var index = vm.topicDataModel.air_forms.indexOf(airform.id);
                vm.topicDataModel.air_forms.splice(index, 1);
                var aIndex = vm.airForms.findIndex(function (air_form) {
                    return air_form.id === airform.id;
                });
                if (aIndex !== -1) {
                    vm.airForms.splice(aIndex, 1);
                }
            }).finally(function () { });
        };

        vm.removeUser = function (user) {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.topic.removeUser'))
                .ariaLabel($filter('translate')('atheer.topic.removeUser'))
                .ok($filter('translate')('entity.action.remove'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                var index = vm.topicDataModel.users.indexOf(user.id);
                vm.topicDataModel.users.splice(index, 1);
                var uIndex = vm.users.findIndex(function (usr) {
                    return usr.id === user.id;
                });
                if (uIndex !== -1) {
                    vm.users.splice(uIndex, 1);
                };
            }).finally(function () { });
        };

        vm.getCardMeta = function (card) {
            var contentMetaData = $filter('filter')(CONTENT_META, {
                type: card.data.type.replace(/_/g, '-')
            })[0];
            return contentMetaData;
        };

        function getModal(success_callback, title) {
            if (!title) {
                title = "atheer.topic.unsavedChanged";
            }
            var confirmModal = $mdDialog
                .confirm()
                .title($filter("translate")(title))
                .ariaLabel($filter("translate")(title))
                .ok($filter("translate")("entity.action.ok"))
                .cancel($filter("translate")("entity.action.cancel"));
            $mdDialog.show(confirmModal).then(function (event) {
                success_callback();
            });
        }

        vm.refresh = function () {
            if (vm.tabView == "SUB-TOPIC") {
                if (!vm.isArrayEqual(vm.old_subTopics, vm.subTopics, "id")) {
                    var success_callback = function () {
                        vm.loadSubTopics(vm.topicDataModel.id);
                    };
                    getModal(success_callback, "atheer.topic.unsavedSubtopic");
                } else {
                    vm.loadSubTopics(vm.topicDataModel.id);
                }
            } else if (vm.tabView == "CONTENTS") {
                if (!vm.isArrayEqual(vm.old_contents, vm.contents, "id")) {
                    var success_callback = function () {
                        vm.loadContents(vm.topicDataModel.id);
                    };
                    getModal(success_callback, "atheer.topic.unsavedContent");
                } else {
                    vm.loadContents(vm.topicDataModel.id);
                }
            }
        };

        vm.loadAll = function () {

            vm.loadingData = true;

            MetaData.get({
                id: 'topic',
                is_name: true
            }, function (result, headers) {
                angular.forEach(result.field_groups, function (field_group) {
                    if (field_group.name == 'base') {
                        vm.topicMeta = field_group;
                    }
                });

                angular.forEach(vm.topicMeta.fields, function (field) {
                    if (field.type == 'FLEX') {
                        vm.topicMetaFields.push(getFormlyField(field));
                    }
                });
                if (id) {
                    Topic.get({
                        id: id
                    }, function (result, headers) {
                        vm.topicDataModel = angular.copy(result);
                        vm.oldTopicDataModel = angular.copy(result);
                        vm.topicType = result.type;
                        vm.loadTeams(vm.topicDataModel);
                        vm.loadSubTopics(vm.topicDataModel.id);
                        vm.loadContents(vm.topicDataModel.id);
                        vm.loadAirForms();
                        vm.loadUsers();

                        if (vm.topicDataModel.owner_id) {
                            vm.loadOwner();
                        };

                        if (vm.topicDataModel.parent_id != null) {
                            vm.loadParentTopic(vm.topicDataModel.parent_id);
                        };

                        if (vm.topicType == 'PAGE' && vm.topicDataModel.content_id != null) {
                            vm.loadPageContent(vm.topicDataModel.content_id);
                        };

                        if (vm.topicDataModel.available_from) {
                            vm.topicDataModel.available_from = new Date(vm.topicDataModel.available_from)
                            vm.oldTopicDataModel.available_from = new Date(vm.oldTopicDataModel.available_from)
                        }
                        if (vm.topicDataModel.discontinue_from) {
                            vm.topicDataModel.discontinue_from = new Date(vm.topicDataModel.discontinue_from)
                            vm.oldTopicDataModel.discontinue_from = new Date(vm.oldTopicDataModel.discontinue_from)
                        };

                        vm.loadingData = false;
                    });
                } else {
                    vm.loadingData = false;
                    vm.topicDataModel = {
                        name: "Topic Title",
                        description: "",
                        external_id: null,
                        type: vm.topicType,
                        status: "DRAFT",
                        enabled: false,
                        allow_scan: false,
                        available_from: new Date(),
                        discontinue_from: null,
                        featured: false,
                        parent_id: null,
                        tags: []
                    };
                    vm.oldTopicDataModel = angular.copy(vm.topicDataModel);
                }
            });


        };

        vm.loadTeams = function (topic) {
            if (topic.audiences && topic.audiences.length > 0) {
                Team.query({
                    query: 'id=in=(' + topic.audiences.toString() + ')',
                }, function (response, headers) {
                    vm.audiences = response;
                });
            }
        };

        vm.launchContentPreview = function (content) {
            ContentAction.previewContent(content);
        };

        vm.transformChip = function (chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
        };

        vm.save = function () {
            if (vm.owner) {
                vm.topicDataModel.owner_id = vm.owner.id
            } else {
                vm.topicDataModel.owner_id = null;
            };

            if (vm.audiences && vm.audiences.length > 0) {
                var audienceIds = [];
                angular.forEach(vm.audiences, function (audience) {
                    audienceIds.push(audience.id);
                });
                vm.topicDataModel.audiences = audienceIds;
            } else {
                vm.topicDataModel.audiences = null;
            }

            if (vm.parentTopic != null) {
                vm.topicDataModel.parent_id = vm.parentTopic.id;
            } else {
                vm.topicDataModel.parent_id = null;
            }

            if (vm.pageContent != null) {
                vm.topicDataModel.content_id = vm.pageContent.id;
            }

            if (vm.topicDataModel.id) {
                Topic.update(vm.topicDataModel, onSaveSuccess);
            } else {
                Topic.save(vm.topicDataModel, onSaveSuccess);
            }

            var addedContent = vm.contents
                .filter(function (content) {
                    return !vm.old_contents.some(function (c) {
                        return c.id === content.id;
                    });
                })
                .map(function (content) {
                    return content.id;
                });

            var removedContent = vm.old_contents
                .filter(function (content) {
                    return !vm.contents.some(function (c) {
                        return c.id === content.id;
                    });
                })
                .map(function (content) {
                    return content.id;
                });
            if (addedContent.length) {
                vm.saveContent(addedContent);
            }
            if (removedContent.length) {
                vm.removeContentData(removedContent);
            }

            function onSaveSuccess(result) {
                id = result.id;
                vm.topicDataModel = angular.copy(result);
                vm.oldTopicDataModel = angular.copy(result);
                vm.loadContents(vm.topicDataModel.id);
                vm.loadSubTopics(vm.topicDataModel.id);
                vm.isSaving = false;
                AlertService.displayToast($mdToast);
            }
        };

        vm.copyTopic = function () {
            vm.topicDataModel.id = null;
            vm.topicDataModel.name = "Copy of " + vm.topicDataModel.name;
            id = null;
        };

        vm.isNew = function () {
            if (!vm.topicDataModel) {
                return false;
            } else {
                if (vm.topicDataModel.id == null) {
                    return true;
                } else {
                    return false;
                }
            }
        };

        vm.togglePanel = function (componentId) {
            $mdSidenav(componentId).toggle();
        };

        vm.transitionSubTopics = function () {
            vm.loadingSubTopics = true;
            vm.promise = Topic.getSubTopics({
                id: vm.topicDataModel.id,
                page: vm.subTopicsPage - 1,
                size: paginationConstants.itemsPerPage
            }).$promise;

            vm.promise.then(function (result, headers) {
                vm.loadingSubTopics = false;
                vm.subTopics = angular.copy(result);
                vm.old_subTopics = angular.copy(result);
                vm.subTopicsQueryCount = headers('X-Total-Count');
            });
        };

        vm.transitionContents = function () {
            vm.loadingContent = true;

            vm.promise = Topic.getContents({
                page: vm.contentsPage - 1,
                size: paginationConstants.itemsPerPage,
                id: vm.topicDataModel.id
            }).$promise;

            vm.promise.then(function (result, headers) {
                vm.loadingContent = false;
                vm.contents = angular.copy(result);
                vm.old_contents = angular.copy(result);
            });
        };

        vm.getDefaultBackground = function () {
            return '#bdbec0';
        };

        vm.hideInfo = function () {
            $timeout(function () {
                if (vm.isHovered) {
                    vm.isHovered = false;
                }
            }, 1000);
        };

        function getFormlyField(atheerfield) {
            var formlytype = FORMLY_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyTemplateType = FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyField = {
                type: formlytype ? formlytype : 'input',
                key: atheerfield.name,
                templateOptions: {
                    label: atheerfield.label,
                    required: atheerfield.required
                }
            };
            if (formlytype == "datepicker") {
                formlyField.templateOptions['placeholder'] = $filter('translate')('atheer.about.selectDate');
            }
            if (formlyTemplateType) {
                formlyField.templateOptions['type'] = formlyTemplateType;
            }

            if (atheerfield.data_type == 'DATETIME') {
                formlyField.templateOptions['datepickerPopup'] = "HH:MM";
            }

            if (atheerfield.data_type == 'ARRAY') {
                formlyField.templateOptions['labelProp'] = "label";
                formlyField.defaultValue = [];
            }

            if (atheerfield.data_type == 'LIST') {
                formlyField.templateOptions['labelProp'] = "label";
                formlyField.templateOptions['valueProp'] = "option_id";
                formlyField.templateOptions['options'] = atheerfield.list_options;
            }

            if (atheerfield.data_type == 'OBJECT') {
                if (atheerfield.ref_object == 'Content') {
                    formlyField.templateOptions['objectName'] = 'Content';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'title',
                        operator: '=re='
                    }];
                } else if (atheerfield.ref_object == 'User') {
                    formlyField.templateOptions['objectName'] = 'User';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'text_search',
                        operator: '=ts='
                    }];
                }
            }
            return formlyField;
        };

        vm.loadAll();
    }
})();
