(function () {
    'use strict';

    angular
        .module('atheer.content')
        .controller('BaseContentDetailController', BaseContentDetailController)

    /* @ngInject */
    function BaseContentDetailController($mdDialog, Principal, User, $stateParams, Feature, $scope, $filter, $sce, $state, $q, AlertService, $mdToast, $mdSidenav, CONTENT_META, Content, ContentAction, Team, Topic, Folder, content_type, id, CONTENT_INSIGHT_META, CONTENT_RECOGNITION_META, MetaData, ToastService, DeeplinkService) {

        this.topics = [];
        this.audiences = [];
        this.folders = [];
        this.searchTopics = searchTopics;
        this.searchTeams = searchTeams;
        this.searchFolders = searchFolders;
        this.loadingData = true;
        this.metaFields = [];
        this.owner = null;
        this.id = id;
        this.allowContentOwner = Feature.isActive('feature_content_allow_owner');
        this.isCopied = false;
        this.downloadVideoShow = false;
        this.downloadVideoDisabled = false;
        $scope.descriptionLimitExceeded = false;

        var FORMLY_ATHEER_DATA_TYPE_MAPPING = {
            'BOOLEAN': 'boolean-switch',
            'STRING': 'input',
            'LIST': 'custom-select',
            'DATE': 'custom-datepicker',
            'DATETIME': 'custom-datepicker',
            'NUMBER': 'input',
            'DECIMAL': 'input',
            'ARRAY': 'custom-chips'
        };

        var FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING = {
            'STRING': 'text',
            'NUMBER': 'number',
            'DECIMAL': 'number'
        };

        this.contentMetaData = angular.copy($filter('filter')(CONTENT_META, {
            type: content_type
        })[0]);

        this.contentMetaData.default_model.title = $filter('translate')(this.contentMetaData.default_model.title);

        this.contentModel = this.contentMetaData.default_model;

        if ($state.params.contentType != undefined && $state.params.contentType.replace(/_/g, '-') === 'smart-content') {
            if (content_type === "smart-content") {
                this.showToolbar = true;
            } else {
                this.showToolbar = false
            }
        } else {
            this.showToolbar = true;
        }

        MetaData.get({
            id: 'content',
            is_name: true
        }, function (result) {
            if (result.field_groups && result.field_groups.length > 0 && result.field_groups[0].fields && result.field_groups[0].fields.length > 0) {
                angular.forEach(result.field_groups[0].fields, function (field) {
                    $scope.vm.metaFields.push(getFormlyField(field));
                });
            }
        });

        function getFormlyField(atheerfield) {
            var formlytype = FORMLY_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyTemplateType = FORMLY_TEMPLATE_TYPE_ATHEER_DATA_TYPE_MAPPING[atheerfield.data_type];
            var formlyField = {
                type: formlytype ? formlytype : 'input',
                key: atheerfield.name,
                templateOptions: {
                    label: atheerfield.label,
                    required: atheerfield.required
                }
            };

            if (formlyTemplateType) {
                formlyField.templateOptions['type'] = formlyTemplateType;
            }

            if (atheerfield.data_type == 'DATETIME') {
                formlyField.templateOptions['datepickerPopup'] = "HH:MM"
            }

            if (atheerfield.data_type == 'ARRAY') {
                formlyField.templateOptions['labelProp'] = "label"
                formlyField.defaultValue = [];
            }

            if (atheerfield.data_type == 'LIST') {
                formlyField.templateOptions['labelProp'] = "label";
                formlyField.templateOptions['valueProp'] = "option_id";
                formlyField.templateOptions['options'] = atheerfield.list_options;
            }

            if (atheerfield.data_type == 'OBJECT') {
                if (atheerfield.ref_object == 'Content') {
                    formlyField.templateOptions['objectName'] = 'Content';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'title',
                        operator: '=re='
                    }];
                } else if (atheerfield.ref_object == 'User') {
                    formlyField.templateOptions['objectName'] = 'User';
                    formlyField.templateOptions['queryObject'] = [{
                        fieldName: 'text_search',
                        operator: '=ts='
                    }];
                }
            }
            return formlyField;
        }

        if (this.showToolbar) {
            this.loadingData = true;
            if (id) {
                if ($stateParams.isDeeplink) {
                    var data = DeeplinkService.getContentData();
                    if (data) {
                        setTimeout(function () {
                            setContentData(data);
                        }, 2000)
                        DeeplinkService.setContentData({});
                    } else {
                        Content.get({
                            id: id
                        }, function (result) {
                            setContentData(result);
                        });
                    }
                } else {
                    Content.get({
                        id: id
                    }, function (result) {
                        setContentData(result);
                    });
                }
            } else {
                if (!this.allowContentOwner && Principal.getPrimaryTeam() != null) {
                    this.owner = Principal.getPrimaryTeam();
                    this.contentModel.owner_id = this.owner.id;
                }
                checkForDownload();
                this.loadingData = false;
            }
        }

        $scope.$watch('contentModel', function (newVal) {
            $scope.previewModel = newVal;
        })

        function setContentData(result) {
            $scope.vm.contentModel = result;

            var queue = [
                Topic.query({
                    query: 'id=in=(' + ($scope.vm.contentModel.data.topics ? $scope.vm.contentModel.data.topics.toString() : 'dummy') + ')'
                }).$promise,
                Team.query({
                    query: 'id=in=(' + ($scope.vm.contentModel.data.audiences ? $scope.vm.contentModel.data.audiences.toString() : 'dummy') + ')'
                }).$promise
            ];

            if ($scope.vm.contentModel.owner_id) {
                queue.push(Team.get({
                    id: $scope.vm.contentModel.owner_id
                }).$promise)
            }

            $q.all(queue).then(function (data) {
                $scope.vm.topics = data[0];
                $scope.vm.audiences = data[1];
                if ($scope.vm.contentModel.owner_id) {
                    $scope.vm.owner = data[2];
                }
                if ($stateParams.copy) {
                    $scope.vm.copyContent();
                    $stateParams.copy = false;
                }
                $scope.vm.loadingData = false;
                checkForDownload();
                if (!$scope.vm.contentModel.hasOwnProperty('tags')) {
                    $scope.vm.contentModel.tags = [];
                }
            });
        }

        function checkForDownload() {
            if ($scope.vm && $scope.vm.contentModel && $scope.vm.contentModel.data) {
                var contentData = $scope.vm.contentModel.data;
                if (contentData.type == 'image' || contentData.type == 'podcast' || contentData.type == 'video') {
                    $scope.vm.downloadVideoShow = true;
                    if (contentData.source_media) {
                        $scope.vm.downloadVideoDisabled = false;
                    }
                    else {
                        $scope.vm.downloadVideoDisabled = true;
                    }
                }
                else {
                    $scope.vm.downloadVideoShow = false;
                }
            }
        }

        this.downloadOnClick = function () {
            var href = ''
            switch (this.contentModel.data.type) {
                case 'image':
                    href = '/media/images/' + this.contentModel.data.source_media.properties.file_name;
                    break;

                case 'video':
                    href = '/media/videos/' + this.contentModel.data.source_media.id + '/' + this.contentModel.data.source_media.properties.file_name;
                    break;

                case 'podcast':
                    href = '/media/audios/' + this.contentModel.data.source_media.properties.file_name;
                    break;
            }
            var link = document.createElement('a');
            link.href = href;
            link.download = this.contentModel.data.source_media.id;
            link.target = '_blank';
            link.click();
        }

        this.showSaveShareButton = function () {
            switch (this.contentModel.data.type) {
                case "image":
                case "video":
                case "podcast":
                case "document":
                    return this.processStatus === "READY";
                case "link":
                    var urlExtractStatus = 'ready';
                    if(this.id !== '' ) {
                        urlExtractStatus = 'idle';
                    }                    
                    return (
                        !this.isURLInvalid() &&
                        (this.extractStatus === urlExtractStatus)
                    );
                default:
                    return true;
            }
        };

        this.onSaveSuccess = function (result) {
            $scope.vm.contentModel = result;

            AlertService.displayToast($mdToast);

            if (!$scope.vm.showToolbar) {
                $scope.$parent.vm.contentModel.data.cards[$scope.$parent.vm.currentSelectedCardIndex] = result;
                $scope.$parent.vm.save();
            }
        };

        this.onSaveSuccessNoAlert = function (result) {
            $scope.vm.contentModel = result;

            if (!$scope.vm.showToolbar) {
                $scope.$parent.vm.contentModel.data.cards[$scope.$parent.vm.currentSelectedCardIndex] = result;
                $scope.$parent.vm.save();
            }
        };

        this.onSaveError = function () {
            $scope.vm.isSaving = false;
        };

        this.onSaveErrorShow = function (error) {
            var error = error.data.$$state.value.description;
            $scope.vm.isSaving = false;
            ToastService.displayToast($filter('translate')('atheer.content.saveFailed', { reason: error }));
        };

        this.save = function () {
            this.updateContentModel();

            if (this.contentModel.id) {
                Content.update(this.contentModel, this.onSaveSuccess, this.onSaveErrorShow);
            } else {
                Content.save(this.contentModel, this.onSaveSuccess, this.onSaveErrorShow);
            }
        };

        this.saveOnAction = function () {
            this.updateContentModel();

            if (this.contentModel.id) {
                Content.update(this.contentModel, this.onSaveSuccessAction, this.onSaveError);
            } else {
                Content.save(this.contentModel, this.onSaveSuccessAction, this.onSaveError);
            }
        };

        this.saveNoAlert = function () {
            this.updateContentModel();

            if (this.contentModel.id) {
                Content.update(this.contentModel, this.onSaveSuccessNoAlert, this.onSaveError);
            } else {
                Content.save(this.contentModel, this.onSaveSuccessNoAlert, this.onSaveError);
            }
        };

        this.updateContentModel = function () {
            //update owner,topics and audiences
            if (this.owner) {
                this.contentModel.owner_id = this.owner.id
            } else {
                this.contentModel.owner_id = null;
            }
            if (this.topics && this.topics.length > 0) {
                var topicIds = [];
                angular.forEach(this.topics, function (topic) {
                    topicIds.push(topic.id);
                });
                this.contentModel.data.topics = topicIds;
            } else {
                this.contentModel.data.topics = null;
            }

            if (this.audiences && this.audiences.length > 0) {
                var audienceIds = [];
                angular.forEach(this.audiences, function (audience) {
                    audienceIds.push(audience.id);
                });
                this.contentModel.data.audiences = audienceIds;
            } else {
                this.contentModel.data.audiences = null;
            }

            if (this.folders && this.folders.length > 0) {
                var folderIds = [];
                angular.forEach(this.folders, function (folder) {
                    folderIds.push(folder.id);
                });
                this.contentModel.folders = folderIds;
            } else {
                this.contentModel.folders = null;
            }

            // article content - set the html entry for consistent rendering on all browsers
            if ( this.contentModel.data.type === "smart_page" ) {
                this.contentModel.data.content = "<head> <meta name=\"viewport\" content=\"width=device-width, initial-scale=1\" /> </head>" + this.contentModel.data.content;
            }
        }

        this.goToContent = function () {
            $state.go("storm.admin-contents");
        };

        this.togglePanel = function (componentId) {
            $mdSidenav(componentId).toggle();
        };

        this.isNew = function () {
            if (this.contentModel == undefined) return;
            if (this.contentModel.id == null && !this.isCopied && !$stateParams.id) {
                return true;
            } else {
                return false;
            }
        }

        this.copyContent = function () {
            delete this.contentModel['id'];
            this.contentModel.title = 'Copy of ' + this.contentModel.title;
            this.isCopied = true;

            ToastService.displayToast($filter('triTranslate')('atheer.content.contentCopied'), 3000)
        }

        this.transformChip = function (chip) {
            if (angular.isObject(chip)) {
                return chip;
            }
        }

        this.openAssignModal = function () {
            ContentAction.openAssignModal([this.contentModel.id], this.contentModel.data.type == 'announcement', false);
        }

        this.openSendModal = function () {
            ContentAction.openSendModal([this.contentModel]);
        }

        this.openShareDialog = function () {
            ContentAction.openShareDialog([this.contentModel.id]);
        }

        this.showAssignAction = function (card) {
            return this.contentMetaData.show_assign_action;
        }

        this.showSendAction = function (card) {
            return this.contentMetaData.type == 'email-template';
        }

        this.showAskAction = function (card) {
            return this.contentMetaData.show_ask_action;
        }

        this.showRecommendAction = function (card) {
            return this.contentMetaData.show_recommend_action;
        }

        this.hasInsights = function () {
            return CONTENT_INSIGHT_META[this.contentModel.data.type] != undefined;
        }

        this.hasRecognition = function () {
            return CONTENT_RECOGNITION_META[this.contentModel.data.type] != undefined;
        }

        this.goBack = function () {
            if ($stateParams.isDeeplink) {
                window.history.go(-2);
            } else if(!!sessionStorage.getItem('DocumentListingQueryParams')) {
                $state.go('storm.content-documents', JSON.parse(sessionStorage.getItem('DocumentListingQueryParams')));
            } else {
                $state.go('storm.admin-contents');
            }
        }

        this.recordAudio = function () {
            $mdDialog.show({
                templateUrl: 'modules/content/content-audio-record-dialog.tmpl.html',
                controller: 'ContentAudioRecordController',
                controllerAs: 'vm',
                clickOutsideToClose: true
            })
                .then(function (media) {
                    $scope.vm.contentModel.audio_id = media.properties.file_name;

                    if (!$scope.vm.showToolbar) {
                        $scope.$parent.vm.saveOnAction();
                    }
                });
        }

        this.clearAudio = function () {
            var confirm = $mdDialog.confirm()
                .title($filter('translate')('atheer.content.clearAudio'))
                .ariaLabel($filter('translate')('atheer.content.clearAudio'))
                .ok($filter('translate')('entity.action.delete'))
                .cancel($filter('translate')('entity.action.cancel'));

            $mdDialog.show(confirm).then(function () {
                $scope.vm.contentModel.audio_id = null;
                if (!$scope.vm.showToolbar) {
                    $scope.$parent.vm.saveOnAction();
                }
            });
        }

        this.froalaOptions = {
            pluginsEnabled: ['align', 'charCounter', 'codeBeautifier', 'codeView', 'colors', 'draggable', 'emoticons', 'entities', 'file', 'fontFamily', 'fontSize', 'fullscreen', 'image', 'inlineStyle', 'lineBreaker', 'link', 'lists', 'paragraphFormat', 'paragraphStyle', 'quote', 'save', 'table', 'url', 'video'],
            toolbarButtons: ['bold', 'italic', 'underline', 'strikeThrough', '|', 'insertLink', 'insertImage', 'fullscreen'],
            toolbarButtonsMD: ['bold', 'italic', 'underline', 'strikeThrough', '|', 'insertLink', 'insertImage', 'fullscreen'],
            toolbarButtonsSM: ['bold', 'italic', 'underline', 'strikeThrough', '|', 'insertLink', 'insertImage', 'fullscreen'],
            toolbarButtonsXS: ['bold', 'italic', 'underline', 'strikeThrough', '|', 'insertLink', 'insertImage', 'fullscreen'],
            charCounterMax: 255,
            placeholderText: $filter('translate')('global.enterTextHerePlaceholder'),
            events: {
                "charCounter.update": function (e) {
                    if (this.el.textContent.length > 255) {
                        $scope.descriptionLimitExceeded = true;
                    } else {
                        $scope.descriptionLimitExceeded = false;
                    }
                    $scope.$apply();
                },
                "paste.before": function (e) {
                    var pastedPlainData = e.clipboardData.getData("text/plain");
                    this.html.insert(pastedPlainData);
                    this.undo.saveStep();
                    $scope.$apply();
                    return false;
                },
            },
        }

        function getQueryString(criteria) {
            if (criteria.length <= 0) {
                return null;
            } else {
                return 'name=re=' + criteria;
            }
        }

        function searchTopics(criteria) {
            var d = $q.defer();
            var result = Topic.query({
                query: getQueryString(criteria)
            }, function (result) {
                d.resolve(result);
            });
            return d.promise;
        }

        function searchTeams(criteria) {
            var d = $q.defer();
            var result = Team.query({
                query: getQueryString(criteria)
            }, function (result) {
                d.resolve(result);
            });
            return d.promise;
        }

        function searchFolders(criteria) {
            var d = $q.defer();
            var result = Folder.query({
                query: getQueryString(criteria)
            }, function (result) {
                d.resolve(result);
            });
            return d.promise;
        }
    }
})();
